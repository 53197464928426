export default {
  props: ['entityType', 'entityId'],
  async mounted() {
    await this.$store.dispatch(`${this.entityType}/refreshTrackingEvents`, this.entityId);
  },
  computed: {
    completionEvent() {
      return `completed_${this.$options.name.toLowerCase().replace(`${this.entityType}client`, '')}`;
    },
    trackingEvents() {
      return this.$store.state[this.entityType].trackingEvents;
    },
  },
  watch: {
    trackingEvents: function (newValue) {
      if (newValue.find(({ event_slug }) => event_slug === this.completionEvent)) {
        this.$router.push({
          name: 'ViewEntity',
          params: {
            entityType: this.entityType,
            entityId: this.entityId,
          },
        });
      }
    },
  },
};
