import axios from 'axios';

export default async function getPdf(reference, document) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/pdfs/${reference}/${document}?prefilled=0`;
  const config = { responseType: 'arraybuffer', headers: { Accept: 'application/pdf' } };
  const response = await axios.get(url, config).catch((error) => {
    console.log(error);
  });

  return Buffer.from(response.data, 'base64');
}
