<script>
export default {
  name: 'SummaryPage',
  props: {
    entityType: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    standaloneToe: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    preview() {
      this.$emit('preview');
    },
    download() {
      this.$emit('download');
    },
    save() {
      this.$emit('save');
    },
    submit() {
      this.$emit('submit');
    },
  },
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col my-2">
      <h2 class="col-md-12">Summary</h2>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10 my-2">
            <h5>
              <b>{{ !isMobile ? 'Preview / ' : '' }}Download </b> your completed document prior to submission to ensure
              all required information has been entered correctly. If anything is missing, please return to the relevant
              section and update prior to submission.
            </h5>
          </div>
          <div class="col-md-2 text-center">
            <div>
              <button
                v-if="!isMobile"
                :disabled="disabled"
                class="btn btn-info border-info my-2 w-100"
                @click="preview"
              >
                <i v-if="disabled" class="spinner icon-spinner2 mr-2"></i>
                <i v-else class="icon-eye8 mr-2"></i>
                Preview
              </button>
            </div>
            <div>
              <button :disabled="disabled" class="btn btn-info border-info my-2 w-100" @click="download">
                <i v-if="disabled" class="spinner icon-spinner2 mr-2"></i>
                <i v-else class="icon-download4 mr-2"></i>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="border-top my-2"></div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10 my-2">
            <h5>
              <b>Save</b> your current progress and return the case menu. You can return at any time to complete your
              document ready for submission.
            </h5>
          </div>
          <div class="col-md-2 text-center">
            <button :disabled="disabled" class="btn bg-success border-success my-2 w-100" @click="save">
              <i v-if="disabled" class="spinner icon-spinner2 mr-2"></i>
              <i v-else class="icon-floppy-disk mr-2"></i>
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="border-top my-2"></div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10 my-2">
            <h5>
              <b>Submit</b> your completed document. Once submitted, no changes may be made to this document and it will
              be distributed for electronic signature by all parties named in the
              {{ (standaloneToe ? 'Transfer of Equity' : entityType) | capitalize }}. Please use the document manager in
              the case home page to upload any required documents to your case. It is important that you notify us of
              any changes to your replies which may occur following the submission of this document at
              {{ $t('site.supportEmail') }}.
            </h5>
          </div>
          <div class="col-md-2 text-center">
            <button :disabled="disabled" class="btn bg-danger border-danger my-2 w-100" @click="submit">
              <i v-if="disabled" class="spinner icon-spinner2 mr-2"></i>
              <i v-else class="icon-checkmark-circle2 mr-2"></i>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped />
